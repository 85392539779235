import { HalfMarathonInAngkorWat, SiemReapTrip } from "./ArticleList/Cambodia";
import { JobFindingInSG, SingaporeLiving } from "./ArticleList/Singapore";
import { baliItinerary, baliSurf, mtBromoTour } from "./ArticleList/Indonesia";

import MBS from "/Img/country/Singapore/MBS.jpg";
import hiltonView from "/Img/country/Singapore/hiltonView.jpg";
import sgCityLine from "/Img/country/Singapore/sgCityLine.jpeg";
import siemReapTemple from "/Img/country/Cambodia/temple1.jpeg";
import siemReapTitle from "/Img/country/Cambodia/siemReapTitle.jpeg";
import surfBali from "/Img/country/Indonesia/Bali/surf.webp";
import templePraying from "/Img/country/Indonesia/Bali/templePraying.jpg";
import volcano from "/Img/country/Indonesia/Bromo/volcano.jpeg";
import volcano2 from "/Img/country/Indonesia/Bromo/volcano2.jpeg";

const CountryDetailedList = [
  //Singapore
  {
    img: sgCityLine,
    title: "新加坡找工作和簽證分享",
    titleImgText: "新加坡城市一景",
    countryKey: "Singapore",
    countryName: "新加坡",
    link: "/Your-Ultimate-Guide-to-Job-Finding-and-Visas-in-Singapore",
    content: JobFindingInSG,
  },

  {
    img: hiltonView,
    title: "實際在新加坡生活要多少錢？",
    titleImgText: "新加坡城市的邊際線",
    countryKey: "Singapore",
    countryName: "新加坡",
    link: "/The-Realistic-Cost-of-Living-in-Singapore-in-2023:-A-Practical-Guide",
    content: (currentLang) => SingaporeLiving(currentLang),
  },

  //Indonesia
  {
    img: volcano,
    title: "印尼布羅莫火山(mount Bromo)日出：你所需要知道的一切！",
    titleImgText: "印尼布羅莫火山",
    countryKey: "Indonesia",
    countryName: "印尼",
    link: "/Mount-Bromo-Sunrise:-Everything-You-Need-To-Know",
    content: mtBromoTour,
  },
  {
    img: templePraying,
    title: "我的七天巴厘島旅程 --- 衝浪、絕美沙灘跟古寺廟",
    titleImgText: "遊客在聖泉寺祈禱",
    countryKey: "Indonesia",
    countryName: "印尼",
    link: "/My-One-Week-Solo-Trip-to-Bali:-Temples,-Surfing,-and-Beaches",
    content: baliItinerary,
  },

  {
    img: surfBali,
    title: "菜鳥浪人在巴厘島的衝浪心得",
    titleImgText: "Ola Eleogram and Eli Olson Photo: Tony Heff",
    countryKey: "Indonesia",
    countryName: "印尼",
    link: "/My-First-Surfing-Adventure-in-Bali-As-An-Beginner",
    content: baliSurf,
  },

  //thailand
  // {
  //   img: volcano,
  //   title: "Mount-Bromo-Sunrise:-Everything-You-Need-To-Know",
  //   titleImgText: "Mount-Bromo-Sunrise:-Everything-You-Need-To-Know_ImgText",
  //   countryKey: "Indonesia",
  //   link: "/Mount-Bromo-Sunrise:-Everything-You-Need-To-Know",
  //   content: mtBromoTour,
  // },

  //India
  // {
  //   img: sgCityLine,
  //   title: "Your-Ultimate-Guide-to-Job-Finding-and-Visas-in-Singapore",
  //   titleImgText:
  //     "Your-Ultimate-Guide-to-Job-Finding-and-Visas-in-Singapore_ImgText",
  //   countryKey: "India",
  //   countryName: "印度",
  //   link: "/Your-Ultimate-Guide-to-Job-Finding-and-Visas-in-Singapore",
  //   content: JobFindingInSG,
  // },

  //cambodia

  {
    img: siemReapTitle,
    title: "在吳哥窟遺址中挑戰人生第一場半馬！",
    titleImgText: "早上日出的吳哥窟",
    countryKey: "Cambodia",
    countryName: "柬埔寨",
    link: "/Half-Marathon-In-AngKor-Wat",
    content: HalfMarathonInAngkorWat,
  },
  {
    img: siemReapTemple,
    title: "暹粒(siem reap)的吳哥窟古城三日遊",
    titleImgText: "",
    countryKey: "Cambodia",
    countryName: "柬埔寨",
    link: "/Siem-Reap-Travel",
    content: SiemReapTrip,
  },
];

export default CountryDetailedList;
