import {
  HeadingTitle,
  ImgTitle,
  ParagraphTitle,
} from "@/components/Typography";

import { Link } from "react-router-dom";
import { handleLangData } from "@/i18n";
import { media } from "@/utility/mediaQueries";
import styled from "styled-components";
import { useData } from "@/utility/state/contextAPI";

const CountryTemplateContainer = styled.div``;

const ArticleContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
`;

const ArticleTextContainer = styled.div`
  position: absolute;
  top: 15px;
  //left: 15px;
  padding: 10px;
  margin: 10px;
  color: black;
  overflow-wrap: break-word;
`;

const ArticleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  margin: 15px;
  cursor: pointer;
`;

const ImgWrapper = styled.div`
  overflow: hidden;
  width: 500px;
  height: 100%;
  border-radius: 7px;
  ${media.medium` width: 350px;`};

  ${media.small` width: 250px;`};
`;

const StyledImg = styled.img`
  transition: transform 0.7s cubic-bezier(0.75, 0, 0.15, 1);
  object-fit: cover;
  width: 100%;
  height: 100%;
  &:hover {
    transform: scale(1.1);
  }
`;

const StyledImgTitle = styled(ImgTitle)`
  background-color: white;
`;

function CountryTemplate({ countryData }) {
  const {
    base: { i18n },
  } = useData();
  const t = handleLangData(i18n);
  console.log("countryData", countryData);
  return (
    <CountryTemplateContainer>
      <HeadingTitle>{countryData[0].countryName}</HeadingTitle>
      <ArticleContainer>
        {countryData.map((data) => {
          return (
            <ArticleWrapper>
              <Link to={data.link}>
                <ImgWrapper>
                  <StyledImg src={data.img} alt={data.title} />
                </ImgWrapper>
                <ArticleTextContainer>
                  <StyledImgTitle>{data.title}</StyledImgTitle>
                </ArticleTextContainer>
              </Link>
            </ArticleWrapper>
          );
        })}
      </ArticleContainer>
    </CountryTemplateContainer>
  );
}

export default CountryTemplate;
