const countryList = [
  {
    name: "亞洲",
    child: [
      {
        name: "新加坡",
        key: "Singapore",
      },

      {
        name: "印尼",
        key: "Indonesia",
      },
      // {
      //   name: "印度",
      //   key: "India",
      // },
      {
        name: "柬埔寨",
        key: "Cambodia",
      },
    ],
  },
  //   {
  //     name: "Europe",
  //     child: [],
  //   },
];

export default countryList;
