import {
  HeadingTitle,
  HighLightWrapper,
  HighlightLink,
  ImgTitle,
  ParagraphBlock,
  ParagraphInlineTitle,
  ParagraphTitle,
  SecondHeadingTitle,
  TextBlock,
} from "@/components/Typography";

import { Divider } from "antd";
import { handleLangData } from "@/i18n";

const SingaporeLiving = (currenLang: string) => {
  const t = handleLangData(currenLang);
  return (
    <>
      <ParagraphBlock>2025年更新</ParagraphBlock>
      <ParagraphBlock>距離寫這篇文章，不知不覺的過了又一年了。</ParagraphBlock>
      <Divider style={{ borderColor: "black" }} />
      <ParagraphBlock>{t["singapore.SingaporeLiving.p1"]}</ParagraphBlock>
      <ParagraphBlock>
        <ParagraphTitle>
          {t["singapore.SingaporeLiving.housingPrice"]}
        </ParagraphTitle>
        <ParagraphBlock>
          {t["singapore.SingaporeLiving.housingP1"]}
        </ParagraphBlock>
        <ParagraphBlock>
          {t["singapore.SingaporeLiving.housingP2"]}
        </ParagraphBlock>
        <ParagraphBlock>
          {t["singapore.SingaporeLiving.housingP3"]}
          <ParagraphInlineTitle>
            <HighLightWrapper>
              {t["singapore.SingaporeLiving.housingP4"]}
            </HighLightWrapper>
          </ParagraphInlineTitle>
          {t["singapore.SingaporeLiving.housingP5"]}
          <ParagraphInlineTitle>
            <HighLightWrapper>
              {t["singapore.SingaporeLiving.housingP6"]}
            </HighLightWrapper>
          </ParagraphInlineTitle>
          {t["singapore.SingaporeLiving.housingP7"]}
        </ParagraphBlock>
        <ParagraphBlock>
          <ParagraphInlineTitle>
            {t["singapore.SingaporeLiving.housingP8"]}
          </ParagraphInlineTitle>
        </ParagraphBlock>
      </ParagraphBlock>
      <ParagraphBlock>
        <ParagraphTitle>
          {t["singapore.SingaporeLiving.commutingCosts"]}
        </ParagraphTitle>
        <ParagraphBlock>
          {t["singapore.SingaporeLiving.commutingCostsP1"]}
          <HighlightLink
            to="https://www.lta.gov.sg/content/ltagov/en/map/fare-calculator.html"
            target="_blank"
            rel="noopener noreferrer"
          >
            fare-calculator
          </HighlightLink>
          <TextBlock>
            {t["singapore.SingaporeLiving.commutingCostsP2"]}
            <HighLightWrapper>
              <ParagraphInlineTitle>
                {t["singapore.SingaporeLiving.commutingCostsP3"]}
              </ParagraphInlineTitle>
            </HighLightWrapper>
          </TextBlock>
        </ParagraphBlock>
      </ParagraphBlock>
      <ParagraphBlock>
        <ParagraphTitle>{t["singapore.SingaporeLiving.food"]}</ParagraphTitle>
        <ParagraphBlock>
          {t["singapore.SingaporeLiving.foodP1"]}
          <TextBlock>{t["singapore.SingaporeLiving.foodP2"]}</TextBlock>
          <TextBlock>
            {" "}
            <HighLightWrapper>
              <ParagraphInlineTitle>
                {t["singapore.SingaporeLiving.foodP3"]}
              </ParagraphInlineTitle>
            </HighLightWrapper>
          </TextBlock>
        </ParagraphBlock>
      </ParagraphBlock>
      <ParagraphBlock>
        <ParagraphTitle>
          {t["singapore.SingaporeLiving.healthcare"]}
        </ParagraphTitle>
        <ParagraphBlock>
          {t["singapore.SingaporeLiving.healthcareP1"]}
          <HighLightWrapper>
            {t["singapore.SingaporeLiving.healthcareP2"]}
          </HighLightWrapper>
        </ParagraphBlock>
      </ParagraphBlock>
      <ParagraphBlock>
        <ParagraphTitle>
          {t["singapore.SingaporeLiving.entertainment"]}
        </ParagraphTitle>
        <ParagraphBlock>
          {t["singapore.SingaporeLiving.entertainmentP1"]}
          <TextBlock>
            {t["singapore.SingaporeLiving.entertainmentP2"]}
          </TextBlock>
          <HighLightWrapper>
            {t["singapore.SingaporeLiving.entertainmentP3"]}
          </HighLightWrapper>
        </ParagraphBlock>
      </ParagraphBlock>
      <ParagraphBlock style={{ marginTop: "30px" }}>
        {t["singapore.SingaporeLiving.summary"]}
        <TextBlock>
          <SecondHeadingTitle>
            {t["singapore.SingaporeLiving.summaryTitle"]}
          </SecondHeadingTitle>

          <TextBlock>
            <HighLightWrapper>
              <ParagraphInlineTitle>
                {t["singapore.SingaporeLiving.summary1"]}
              </ParagraphInlineTitle>
            </HighLightWrapper>
          </TextBlock>
          <TextBlock>{t["singapore.SingaporeLiving.summary2"]}</TextBlock>
          <TextBlock>{t["singapore.SingaporeLiving.summary3"]}</TextBlock>
        </TextBlock>
      </ParagraphBlock>
    </>
  );
};

const JobFindingInSG = (a) => {
  const t = handleLangData(a);
  return (
    <>
      <ParagraphBlock>{t["jobGuide.greeting"]}</ParagraphBlock>
      <HeadingTitle>{t["jobGuide.applyPlatform.title"]}</HeadingTitle>
      <ParagraphBlock>{t["jobGuide.applyPlatform.content"]}</ParagraphBlock>
      <HeadingTitle>{t["jobGuide.visaOptions.title"]}</HeadingTitle>
      <ParagraphBlock>
        <TextBlock>
          <ParagraphInlineTitle>
            {t["jobGuide.visaOptions.wp"]}
          </ParagraphInlineTitle>{" "}
          {t["jobGuide.visaOptions.wpDescription"]}
        </TextBlock>
        <TextBlock>
          <ParagraphInlineTitle>
            {t["jobGuide.visaOptions.sp"]}
          </ParagraphInlineTitle>{" "}
          {t["jobGuide.visaOptions.spDescription"]}
        </TextBlock>
        <TextBlock>
          <ParagraphInlineTitle>
            {t["jobGuide.visaOptions.ep"]}
          </ParagraphInlineTitle>{" "}
          {t["jobGuide.visaOptions.epDescription"]}
        </TextBlock>
        <TextBlock>
          <ParagraphInlineTitle>
            {t["jobGuide.visaOptions.pr"]}
          </ParagraphInlineTitle>{" "}
        </TextBlock>
        <TextBlock>
          <ParagraphInlineTitle>
            <HighLightWrapper>{t["jobGuide.visaOptions.pr1"]}</HighLightWrapper>
          </ParagraphInlineTitle>
        </TextBlock>
        <TextBlock>
          <ParagraphInlineTitle>
            <HighLightWrapper>
              {t["jobGuide.visaOptions.pr2"]}
              <HighlightLink
                to="https://www.mom.gov.sg/passes-and-permits"
                target="_blank"
                rel="noopener noreferrer"
              >
                MOM regulations
              </HighlightLink>
              {t["jobGuide.visaOptions.pr3"]}
            </HighLightWrapper>
          </ParagraphInlineTitle>
        </TextBlock>

        <SecondHeadingTitle>
          {t["jobGuide.prDifferences.title"]}
        </SecondHeadingTitle>
        <TextBlock>{t["jobGuide.prDifferences.autonomy"]}</TextBlock>
        <TextBlock>
          {t["jobGuide.prDifferences.cpf"]}
          <TextBlock>
            {t["jobGuide.prDifferences.additionalBenefits"]}
            <ParagraphBlock>
              <ParagraphInlineTitle>
                {t["jobGuide.prDifferences.hdbHousing"]}
              </ParagraphInlineTitle>{" "}
              {t["jobGuide.prDifferences.hdbHousingDescription"]}
            </ParagraphBlock>
            <ParagraphBlock>
              <ParagraphInlineTitle>
                {t["jobGuide.prDifferences.pathToCitizenship"]}
              </ParagraphInlineTitle>{" "}
              {t["jobGuide.prDifferences.pathToCitizenshipDescription"]}
            </ParagraphBlock>
          </TextBlock>
        </TextBlock>
        <HeadingTitle>{t["jobGuide.applyTips.title"]}</HeadingTitle>
        <ParagraphBlock>
          <HighLightWrapper>
            {t["jobGuide.applyTips.thoroughResearch"]}
          </HighLightWrapper>
        </ParagraphBlock>
        <ParagraphBlock>
          <HighLightWrapper>
            {t["jobGuide.applyTips.prepareDocuments"]}
          </HighLightWrapper>
        </ParagraphBlock>
        <ParagraphBlock>
          <HighLightWrapper>
            {t["jobGuide.applyTips.stayPersistent"]}
          </HighLightWrapper>
        </ParagraphBlock>
      </ParagraphBlock>
      <ParagraphBlock>{t["jobGuide.conclusion"]}</ParagraphBlock>
    </>
  );
};

export { SingaporeLiving, JobFindingInSG };
