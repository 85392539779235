import { Link } from "react-router-dom";
import { ParagraphTitle } from "@/components/Typography";
import countryList from "@/localData/countryList";
import { handleLangData } from "@/i18n";
import { media } from "@/utility/mediaQueries";
import styled from "styled-components";
import { useData } from "@/utility/state/contextAPI";

const CountryMainContainer = styled.div`
  border-radius: 20px;
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.05), -2px 2px 2px rgba(0, 0, 0, 0.04),
    2px 2px 2px rgba(0, 0, 0, 0.05), 0 0 2px rgba(0, 0, 0, 0.08);
  padding: 30px;
  width: 990px;
  display: flex;
  justify-content: center;
  flex-direction: column;
`;

const ContentContainer = styled.div`
  /* display: flex;
  flex-direction: row; */
`;
const RegionContainer = styled.div`
  display: flex;
  flex-direction: column;
  //border-right: 1px solid #f5f4f2;
`;

const CountryListContainer = styled.div`
  display: flex;
  //justify-content: space-between;
  flex-direction: row;

  ${media.medium`flex-direction: column`};
`;

const RegionWrapper = styled.div`
  padding: 18px;
  text-align: center;
`;

const CountryBox = styled(Link)`
  background-color: #f5f4f2;
  border-radius: 20px;
  padding: 10px 5px;
  text-align: center;
  width: 150px;
  margin: 15px;
  text-overflow: ellipsis;
  cursor: pointer;
  &:hover {
    background-color: #e8e7e5;
  }
`;

const CountryBoxWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

function DestinationsPage() {
  const {
    base: { i18n, contextSetI18n },
  } = useData();
  const t = handleLangData(i18n);
  return (
    <CountryMainContainer>
      <ParagraphTitle style={{ textAlign: "center", marginBottom: "0.5rem" }}>
        {t["destinations.pickACountry"]}
      </ParagraphTitle>
      <CountryListContainer>
        {countryList.map((data) => {
          return (
            <RegionContainer>
              <RegionWrapper>
                <div>{data.name}</div>
              </RegionWrapper>
              <CountryBoxWrapper>
                {data.child.map((x) => (
                  <>
                    <CountryBox to={"/" + x.key}>{x.name}</CountryBox>
                  </>
                ))}
              </CountryBoxWrapper>
            </RegionContainer>
          );
        })}
      </CountryListContainer>
    </CountryMainContainer>
  );
}
export default DestinationsPage;
