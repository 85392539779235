import {
  HeadingTitle,
  HighLightWrapper,
  HighlightLink,
  ImgTitle,
  ParagraphBlock,
  ParagraphInlineTitle,
  ParagraphTitle,
  SecondHeadingTitle,
  TextBlock,
} from "@/components/Typography";

import { Divider } from "antd";
import TitleImage from "@/components/shared/TitleImage";
import Video from "@/components/shared/Video";
import apopo from "/Img/country/Cambodia/apopo.jpg";
import finishTheRun from "/Img/country/Cambodia/finishTheRun.jpg";
import { handleLangData } from "@/i18n";
import runningVideo from "/Img/country/Cambodia/runningVideo.mp4";
import startTheRun from "/Img/country/Cambodia/startTheRun.jpg";
import temple1 from "/Img/country/Cambodia/temple1.jpeg";
import temple2 from "/Img/country/Cambodia/temple2.jpeg";
import templeBike from "/Img/country/Cambodia/templeBike.jpg";
import templePeople from "/Img/country/Cambodia/templePeople.jpeg";
import templeSunrise from "/Img/country/Cambodia/templeSunrise.jpeg";
import tuktukFamily from "/Img/country/Cambodia/tuktukFamily.jpg";
import tuktukHome from "/Img/country/Cambodia/tuktukHome.jpg";

const HalfMarathonInAngkorWat = (currenLang: string) => {
  return (
    <>
      <HeadingTitle>緣由</HeadingTitle>
      <ParagraphBlock>
        這一切的開始都是因為我在2024年訂下了一個目標要跑年底的新加坡渣打全馬，在練跑一個多月之後，發現居然在吳哥窟這個古蹟之都每年都會舉辦兩場馬拉松賽事，並且就在當時的一個月後就有其中一場賽事，所以我就馬上手刀報名了，所以想說藉著著個機會來當作全馬前的練習並且趁機去柬埔寨旅遊。
      </ParagraphBlock>

      <HeadingTitle>事先準備</HeadingTitle>
      <ParagraphBlock>
        在吳哥窟的馬拉松有兩個，一個是辦在八月的Angkor Empire
        Marathon另一個則是更盛大的Angkor Wat International Half
        marathon，我參加的前者，我網上報名的連結在下方，整個網上報名過程是挺簡單流暢的。
        <div>
          <HighlightLink
            to="https://worldsmarathons.com/marathon/the-angkor-empire-marathon"
            target="_blank"
            rel="noopener noreferrer"
          >
            Angkor Empire Marathon
          </HighlightLink>
        </div>
      </ParagraphBlock>

      <ParagraphBlock>
        買票之後，下一階段就是整理行李，八月的吳哥窟天氣剛好恰逢雨季，所以雨具跟多的衣服是必備的，天氣偏涼爽。
      </ParagraphBlock>

      <HeadingTitle>跑半馬當天</HeadingTitle>
      <ParagraphBlock>
        <TitleImage
          src={startTheRun}
          titleImgText={"起跑前下一場小雨，好險起跑後雨就停了"}
        />
        跑半馬的當天很幸運的遇到沒下雨的陰天，因此讓整個過程輕鬆很多。一聲槍響下，大家朝著出發的方向衝了出去，我則是慢慢走在後面照著自己的速度去跑。觀察下來，來參加的人有七成是當地人，三成是外國人，整場比賽是很多人參加的，包含很多個贊助商也有來到現場擺攤，整體我覺得安排的很不錯。
        因為我跑的是21k半馬，所以路線是繞著小吳哥古蹟跑一圈，所以可以邊跑邊看到吳哥古城牆跟城門，當下跑過門的時候真的很震撼，當初在歷史書上看到的古城門，現在居然就在眼前！
        <Video width="400" height={240} controls videoDescription={"mt Bromo"}>
          <source src={runningVideo} type="video/mp4" />
        </Video>
        另一個我很喜歡的地方是這裡的熱情，沿路都有大人小朋友來幫你加油跟擊掌，這在最後最後幾公里的時候幫助真的很大！
      </ParagraphBlock>

      <ParagraphBlock>
        <TitleImage
          src={finishTheRun}
          titleImgText={"最後成功跑完人生第一個半馬拉！"}
        />
      </ParagraphBlock>

      <ParagraphBlock>
        總結來說，來吳哥窟裡面跑人生第一場半馬實在是很特別的體驗，很多段都是我一個人在古城內藤蔓環繞的馬路上慢跑，透過這個機會好好的用不同的心情跟角度來認識這個過去稱霸一方的古文明。
      </ParagraphBlock>
    </>
  );
};

const SiemReapTrip = (currenLang: string) => {
  return (
    <>
      <ParagraphBlock>
        在吳哥窟跑完人生第一場半馬之後，如果還沒有看過我的半馬經驗的，可以看
        <HighlightLink
          to="https://worldsmarathons.com/marathon/the-angkor-empire-marathon"
          target="_blank"
          rel="noopener noreferrer"
        >
          這篇文章
        </HighlightLink>
        ，我在吳哥窟又待了三天，來好好完整的認識暹粒(Siem
        reap)，吳哥窟所在的城鎮，的過去現在跟未來。
      </ParagraphBlock>

      <HeadingTitle>事先準備</HeadingTitle>
      <ParagraphBlock>
        這次旅途，我唯一事前準備的就是去網上找了一本蔣勳的吳哥之美，老實說還花我不少功夫才在新加坡找到這本書，因為新加坡還是以英文書為主，當時找了老半天發現新加坡只有一個叫
        <HighlightLink
          to="https://yuyi.com.sg/products/9787535668776"
          target="_blank"
          rel="noopener noreferrer"
        >
          友誼書齋
        </HighlightLink>
        的書店有賣，還特底跑一趟才買到。買到這本書之後，我在行前一個月左右看完然後對吳哥窟有了基本的認識，後面的行程我也是追尋著蔣勳的文字，一步步的來認識這個古老國度。
      </ParagraphBlock>

      <HeadingTitle>我推薦的的三天/四天行程</HeadingTitle>
      <ParagraphBlock>
        <ParagraphBlock>
          <SecondHeadingTitle>第一天</SecondHeadingTitle>
          <ParagraphBlock>
            吳哥窟的小圈行程，其中包含以下幾個重點景點。
            <ParagraphBlock>
              <ParagraphBlock>吳哥地標寺廟</ParagraphBlock>
              <ParagraphBlock>
                大吳哥城南門 Angkor Thom South Gate
              </ParagraphBlock>
              <ParagraphBlock>巴揚寺(微笑的高棉雕像)</ParagraphBlock>
              <ParagraphBlock> 空中宮殿 (Phimeanakas) </ParagraphBlock>
              <ParagraphBlock> 塔普倫寺(古墓奇兵電影拍攝場景) </ParagraphBlock>
              <ParagraphBlock>鬥象台 (Terrace of the Elephants)</ParagraphBlock>
            </ParagraphBlock>
          </ParagraphBlock>
        </ParagraphBlock>
      </ParagraphBlock>
      <ParagraphBlock>
        <ParagraphBlock>
          <SecondHeadingTitle>第二天</SecondHeadingTitle>
          <ParagraphBlock>
            吳哥窟的大圈行程，其中包含以下幾個重點景點。
            <ParagraphBlock>
              <ParagraphBlock>皇家浴池 Srah Srang</ParagraphBlock>
              <ParagraphBlock>變身塔 Pre Rup</ParagraphBlock>
              <ParagraphBlock> 寶劍/聖劍寺 Preah Khan</ParagraphBlock>
              <ParagraphBlock> 巴肯山 Phnom Bakheng(日出景點)</ParagraphBlock>
            </ParagraphBlock>
          </ParagraphBlock>
        </ParagraphBlock>
      </ParagraphBlock>
      <ParagraphBlock>
        <ParagraphBlock>
          <SecondHeadingTitle>第三天</SecondHeadingTitle>
          <ParagraphBlock>
            Apopo，一個非營利組織，用特別訓練的動物來找到未被發現的地雷。
            <div>
              <HighlightLink
                to="https://apopo.org/support-us/apopo-visitor-center/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Apopo網址
              </HighlightLink>
            </div>
          </ParagraphBlock>
          <ParagraphBlock>
            柬埔寨地雷博物館，由一個拆地雷專家所成立，講述柬埔寨的歷史，包含為什麼柬埔寨會被埋下這麼多地雷等等，實地還有真實已拆除的地雷可以觀賞。
            <div>
              <HighlightLink
                to="https://www.cambodialandminemuseum.org/"
                target="_blank"
                rel="noopener noreferrer"
              >
                柬埔寨地雷博物館網址
              </HighlightLink>
            </div>
          </ParagraphBlock>
        </ParagraphBlock>
        <ParagraphBlock>
          <SecondHeadingTitle>第四天</SecondHeadingTitle>
          <ParagraphBlock>
            吳哥窟的水上漁村，我參加Klook的
            <HighlightLink
              to=" https://www.klook.com/zh-TW/activity/29603-kampong-phluk-floating-village-tour/"
              target="_blank"
              rel="noopener noreferrer"
            >
              半天導覽團，介紹當地的水上漁村。
            </HighlightLink>
          </ParagraphBlock>
        </ParagraphBlock>
        <HighLightWrapper>
          第三天跟第四天的行程可以相互調換，我自己比較推薦Apopo +
          地雷博物館的行程，因為可以學習到很多柬埔寨過往戰爭帶來的影響跟如何從中振作。
        </HighLightWrapper>
      </ParagraphBlock>
      <ParagraphBlock>
        <HeadingTitle>行程</HeadingTitle>
        <ParagraphBlock>
          <SecondHeadingTitle>第一天</SecondHeadingTitle>
          <TitleImage src={temple1} titleImgText={"微笑的高棉"} />
          第一天我有在klook上面買半天導覽，裡面包含了日出、交通、基本的導覽跟幫你拍照，但我覺得的導覽的品質很見仁見智，運氣好的話可以遇到介紹很詳細的當地導遊，運氣不好的話，導遊就講得很普通，所以我覺得不一定要參加。之後幾天我就沒有再找導遊了，
          基本上就帶著蔣勳的吳哥之美，邊走邊看書上的解釋來認識吳哥窟。
          <ParagraphBlock>
            <ParagraphTitle>日出</ParagraphTitle>
            <TitleImage src={templeSunrise} titleImgText={"吳哥寺的日出"} />
            日出這個行程我是跟著半天旅遊團去，我會建議如果單獨去的話，最好四五點就出發，因為參加的人很多，並且可以拍到水中倒影的點(有兩個，一個是吳哥寺右邊跟左邊的水池)都很早就有人卡位了，所以可以的話越早去排隊卡位越好。
          </ParagraphBlock>
          <ParagraphBlock>
            <ParagraphTitle>吳哥寺壁畫</ParagraphTitle>
            <TitleImage src={temple2} titleImgText={"吳哥寺內的壁畫"} />
            看完日出之後，就可以進入吳哥寺來參觀，這是在吳哥寺廟裡面的壁畫像，非常的狀觀跟細緻，這裡會推薦跟著導遊或是(偷聽?)旁邊旅遊團的解說才能比較好的了解設計的來龍去脈！
          </ParagraphBlock>
        </ParagraphBlock>

        <ParagraphBlock>
          <SecondHeadingTitle>第二天</SecondHeadingTitle>
          <ParagraphBlock>
            <TitleImage src={templeBike} titleImgText={"吳哥窟小圈城門"} />
          </ParagraphBlock>
          第二天我走的是大圈的行程，這裡我推薦可以找一個固定嘟嘟車司機，直接包一天的行程這樣會比較方便！所以我就一樣跟著蔣勳的書去大圈的幾個重點景點，我覺得吳哥窟本身安排兩天差不多，再多到三天四天就會有審美疲勞了！
          <ParagraphBlock>
            <TitleImage src={templePeople} />
            這裡要注意的地方是很多寺廟會有這樣的人坐在地上，然後揮手要你過去來給你“祝福”手環或是手鍊，等到幫你“祝福”完之後，他就會跟你要錢，我自己覺得這是有點被騙錢的感覺，因為自己是被強迫給錢，所以這裡要多注意一下如果不想多付錢的話要多注意對你特別熱情的當地人！
          </ParagraphBlock>
        </ParagraphBlock>

        <ParagraphBlock>
          <SecondHeadingTitle>第三天/第四天</SecondHeadingTitle>
          <ParagraphBlock>
            <TitleImage
              src={apopo}
              titleImgText={"Apopo裡面用來找地雷的老鼠"}
            />
          </ParagraphBlock>
          <ParagraphBlock>
            第三天我去了Apopo跟地雷博物館，兩個地方都做得非常好，尤其是Apopo，裡面介紹他們如何訓練老鼠來找出哪裡有潛在的地雷，再去一步步的清除，並且他們還實際帶出老鼠示範一次他們怎麼找地雷的！我覺得是一個非常好的景點，可以更深入的去了解柬埔寨的地雷問題跟外國人道組織如何來解決這個問題。我覺得這是來到吳哥窟必去的景點！
            第四天我去了Klook的半天水上漁村，和Apopo相比之下，這個就比較沒有特色，其他東南亞國家多少都有類似的部落，所以我覺得只有三天的話這個行程就可以跳過！
          </ParagraphBlock>
        </ParagraphBlock>
      </ParagraphBlock>
      <ParagraphBlock>
        <SecondHeadingTitle>Bonus: 意外的行程</SecondHeadingTitle>
        <ParagraphBlock>
          <TitleImage src={tuktukHome} titleImgText={`嘟嘟車司機家的"廚房"`} />
          在第三天的行程結束之後，我這三天的嘟嘟車司機問我要不要去參觀他家，前情提要一下，我整趟吳哥窟之旅都包他的嘟嘟車，包含前幾天去跑馬拉松到後面每天早上都要他四點來接我因為我要去看日出，而跟他相處了四五天也是有培養出一些感情，所以我就答應去他家參觀了。他家住在距離城鎮中心三十分鐘的水上小鎮，房子也都是用木材挑高的，來避免雨季的時候房子進水，所以其實跟之前去的水上部落很相近。
        </ParagraphBlock>

        <ParagraphBlock>
          到他家之後他介紹了他的老婆跟小孩給我，並且帶我參觀了他的“家”，但其實就是一個鐵皮屋，也沒有房間都是開放空間組成的空間，裡面連床都沒有只放了一張吊床，衛浴設備可以說是簡陋到不行，廚房則就是一個櫃子裡面放了卡司爐跟常用醬料。在這裡，我深刻的感受到當地人們生活的辛苦跟不易。
        </ParagraphBlock>
        <ParagraphBlock>
          接著他請他老婆煮了魚湯跟拿些小酒出來一起吃，
          老實說我是非常感動的，因為我相信對他來說，能吃到於絕對是不便宜的，但他還是願意拿出來分享給客人。就在是這裡我看到了最底層的經濟環境下的人的生活，這是普通生活在台灣或是其他已開發國家所不能感受到的，但同時我也在最貧窮的地方，感受到了人心的溫暖跟善良。
          <TitleImage src={tuktukFamily} titleImgText={`嘟嘟車司機全家福`} />
          在臨走前，我用相機幫他們拍了一張全家福，我想這是我唯一能幫他們做的，用相機來記錄下他們全家的樣子。
          就這樣，我的吳哥窟之旅在這樣的一個特別的晚上劃下句點。
        </ParagraphBlock>
      </ParagraphBlock>
      <ParagraphBlock>
        <HeadingTitle>總結</HeadingTitle>
        <ParagraphBlock>
          總結一下，吳哥窟我覺得是每個人一輩子必來的地方，當地的人心純樸、治安良好，不管是古代的吳哥王朝遺址或是現代的APOPO人道組織都很值得去觀賞，所以我推薦所有朋友有機會一定要來一趟吳哥窟，戴上一本蔣勳的吳哥之美，來體驗不一樣的世界。
        </ParagraphBlock>
      </ParagraphBlock>
    </>
  );
};

const PhnomPenhTrip = (a) => {};

export { HalfMarathonInAngkorWat, SiemReapTrip, PhnomPenhTrip };
