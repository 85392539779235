import "./index.css";

import {
  Navigate,
  RouterProvider,
  createBrowserRouter,
} from "react-router-dom";

import AboutMePage from "@/pages/AboutMe";
import App from "./App";
import { BaseContextProvider } from "@/utility/state/contextAPI";
import CountryDetailedList from "@/localData/countryDetailedList";
import CountryMainTemplate from "./pages/Countries/CountryMainTemplate";
import CountryTemplate from "./pages/Countries/CountryTemplate";
import DestinationsPage from "@/pages/DestinationsPage";
import ErrorPage from "@/pages/ErrorPage";
import HelmetWrapper from "@/components/helmetWrapper";
import { StrictMode } from "react";
import { createRoot } from "react-dom/client";

const container = document.getElementById("root");

const articleList = CountryDetailedList.map((countryData) => ({
  path: countryData.link,
  element: <CountryMainTemplate countryData={countryData} />,
}));
const getCountryListData = (listData, countryName) => {
  return listData.filter((t) => t.countryKey === countryName);
};

const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <>
        <App />
      </>
    ),
    errorElement: <ErrorPage />,
    children: [
      {
        path: "/aboutMe",
        element: (
          <HelmetWrapper title="About Me">
            <AboutMePage />
          </HelmetWrapper>
        ),
      },
      {
        path: "/destinations",
        element: (
          <HelmetWrapper title="Destinations">
            <DestinationsPage />
          </HelmetWrapper>
        ),
      },
      {
        path: "/life-in-SG",
        element: (
          <HelmetWrapper title="Life in SG">
            <div>Life in SG</div>
          </HelmetWrapper>
        ),
      },
      // {
      //   path: "/travel-tips",
      //   element: (
      //     <>
      //       <div>Travel tips</div>
      //     </>
      //   ),
      // },
      {
        path: "/Singapore",
        element: (
          <CountryTemplate
            countryData={getCountryListData(CountryDetailedList, "Singapore")}
          />
        ),
      },
      {
        path: "/Indonesia",
        element: (
          <CountryTemplate
            countryData={getCountryListData(CountryDetailedList, "Indonesia")}
          />
        ),
      },
      {
        path: "/Cambodia",
        element: (
          <CountryTemplate
            countryData={getCountryListData(CountryDetailedList, "Cambodia")}
          />
        ),
      },
      {
        path: "",
        element: <Navigate to="/aboutMe" />,
      },

      // {
      //   path: "/India",
      //   element: (
      //     <CountryTemplate
      //       countryData={getCountryListData(CountryDetailedList, "India")}
      //     />
      //   ),
      // },
      // {
      //   path: "/Thailand",
      //   element: (
      //     <CountryTemplate
      //       countryData={getCountryListData(CountryDetailedList, "Indonesia")}
      //     />
      //   ),
      // },
      /**
       *
       * Article starts here...
       *
       *
       *
       **/
      ...articleList,
    ],
  },
]);

if (container) {
  const root = createRoot(container);
  root.render(
    <StrictMode>
      <BaseContextProvider>
        <RouterProvider router={router} />
      </BaseContextProvider>
    </StrictMode>
  );
}
