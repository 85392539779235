import React, { useState } from "react";
import styled, { css } from "styled-components";

import AntdSelect from "./shared/AntdSelect";
import { Link } from "react-router-dom";
import { ParagraphTitle } from "@/components/Typography";
import { handleLangData } from "@/i18n";
import { useData } from "@/utility/state/contextAPI";

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 10px 20px;
  color: black;

  @media (max-width: 996px) {
    flex-direction: column;
    align-items: center;
  }

  //background-color: ${(props) => (props.isOpen ? "#f0e8e7" : "white")};
`;

const StyledLink = styled(Link)`
  margin: 10px;
  text-decoration: none;
  cursor: pointer;
`;

const StyledLinkTitle = styled(StyledLink)``;

const Hamburger = styled.div`
  cursor: pointer;
  display: none;
  width: 30px;
  height: 30px;

  @media (max-width: 996px) {
    //display: block;
    display: flex;
    flex-direction: column;
    justify-content: center;
    order: 2; /* Move the hamburger to the rightmost position */
  }
`;

const MenuIcon = styled.div`
  width: 30px;
  height: 3px;
  background-color: black;
  margin: 3px 0;
  transition: all 0.3s linear;

  &:first-child {
    transform: ${(props) => (props.isOpen ? "rotate(45deg)" : "none")};
    transform-origin: 1px;
  }

  &:nth-child(2) {
    opacity: ${(props) => (props.isOpen ? "0" : "1")};
  }

  &:last-child {
    transform: ${(props) => (props.isOpen ? "rotate(-45deg)" : "none")};
    transform-origin: 10% 90%;
  }
`;

const DestinationDropdownContainer = styled.div`
  margin: 10px;
`;

const DivDropdown = styled.div`
  padding: 10px;
  position: absolute;
  //top: 100%; // Position the dropdown below the header link
  //left: 0;
  //display: none;
`;

const LinksContainer = styled.div`
  display: flex;
  white-space: nowrap;
  align-items: center;

  @media (max-width: 996px) {
    display: none;
    ${({ isOpen }) =>
      isOpen &&
      css`
        display: flex;
        flex-direction: column;
        align-items: center;
      `}
  }
`;

const TitleContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
`;

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);
  const {
    base: { i18n, contextSetI18n },
  } = useData();
  const t = handleLangData(i18n);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const options = [
    { value: "English", label: "English" },
    { value: "Chinese", label: "中文" },
  ];

  const handleChange = (e) => {
    contextSetI18n(e);
    toggleMenu();
  };

  return (
    <HeaderContainer isOpen={isOpen}>
      <TitleContainer>
        <StyledLinkTitle to="/aboutMe">bywater的旅遊日記</StyledLinkTitle>
        <Hamburger onClick={toggleMenu}>
          <MenuIcon isOpen={isOpen} />
          <MenuIcon isOpen={isOpen} />
          <MenuIcon isOpen={isOpen} />
        </Hamburger>
      </TitleContainer>
      <LinksContainer isOpen={isOpen}>
        <StyledLink to={"/destinations"} onClick={toggleMenu}>
          <ParagraphTitle>{t["header.destinations"]}</ParagraphTitle>
        </StyledLink>
        <StyledLink onClick={toggleMenu} to={`/Singapore`}>
          <ParagraphTitle>{t["header.lifeInSingapore"]}</ParagraphTitle>
        </StyledLink>
        {/* <StyledLink onClick={toggleMenu} to={`travel-tips`}>
          <ParagraphTitle>{t["header.travelTips"]}</ParagraphTitle>
        </StyledLink> */}
        <StyledLink onClick={toggleMenu} to={`/aboutMe`}>
          <ParagraphTitle>{t["header.aboutMe"]}</ParagraphTitle>
        </StyledLink>
        {/* <AntdSelect
          value={localStorage.getItem("lang") || options[0]}
          handleChange={handleChange}
          options={options}
        /> */}
      </LinksContainer>
      {/* //right now dont need these, after accumulate more photo can do it
      <LinksContainer style={{ flexShrink: "0" }}>
        <StyledLink to={`/`}>Pinterest</StyledLink>
        <StyledLink to={`/`}>Instagram</StyledLink>
      </LinksContainer> */}
    </HeaderContainer>
  );
};

export default Header;
